import { SortDirection } from '../../../typings/backend-types';

export function toggleSortDirection(
  currentSortDirection: SortDirection | undefined | null,
): SortDirection | null {
  const sortStates = [null, SortDirection.ASC, SortDirection.DESC];

  const nextSortState: number =
    (sortStates.findIndex((ss) => ss === currentSortDirection) + 1) % 3;

  return sortStates[nextSortState];
}
