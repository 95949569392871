import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/nextjs';
import { SortDirection } from '../../../typings/backend-types';
import { SimpleTable, TableHeader } from '../SimpleTable';
import { SortIndicator } from '../SortIndicator';
import { AdditionalButton } from '../PagedTable';
import { DynamicAsyncExportButton } from '../../Common/ExportImport/DynamicAsyncExportButton';
import { useErrorPopupContext } from '../../../context/errorPopupContext';
import { toggleSortDirection } from './SortedFilteredTableHelpers';
import { TableCell } from './TableCell';
import styles from '@styles/PagedTable.module.css';
import { classNames } from 'utilities/classNames';
import { LoadingIndicator } from '@components/Common/LoadingIndicator';

export interface Column<T> {
  id?: string;
  header: string;
  prop: (row: T) => string | JSX.Element | JSX.Element[] | number;
  url?: (row: T) => string;
  align?: 'left' | 'right' | 'center';
  isSortable?: boolean;
}

export interface SortingOptions {
  sortBy?: string | null;
  direction?: SortDirection | null;
}

export interface ExportOptions<T> {
  exportable?: boolean;
  exportTransform?: (row: T) => Record<string, unknown>;
  exportFileName?: string | null | undefined;
}

export interface FullExportOptions<T> extends ExportOptions<T> {
  exportData: T[] | (() => Promise<T[]>) | null;
}

export type FetchUrl = (query?: string, sortOptions?: SortingOptions) => string;

interface Props<T> {
  columns: Column<T>[];
  altText?: string | null;
  onRowClick?: (row: T) => void;
  rowLink?: (row: T) => string;
  searchPlaceholder?: string;
  showSearch?: boolean;
  exportOptions?: FullExportOptions<T>;
  toolbar?: JSX.Element;
  additionalButtons?: AdditionalButton | AdditionalButton[];
  rows: T[];
  from?: number;
  totalCount?: number;
  onNext: () => void;
  onPrev: () => void;
  onSortOptionsChange?: (sortOptions: SortingOptions) => void;
  onFilterTextChange?: (filterText: string) => void;
  isValidating?: boolean;
  wrapToolbar?: boolean;
}

/**
 * A wrapper for the tailwind simple table https://tailwindui.com/components/application-ui/lists/tables#component-3de290cc969415f170748791a9d263a6
 * Contains a footer for navigation if the parameters from, to and count and/or onPrev and onNext are provided
 * for an example, see StudentsOverview
 */
export function SortedFilteredTable<T extends { id?: string }>({
  columns,
  altText,
  onRowClick,
  rowLink,
  searchPlaceholder,
  showSearch,
  exportOptions,
  additionalButtons,
  toolbar,
  rows,
  from = 0,
  totalCount,
  onNext,
  onPrev,
  onSortOptionsChange,
  onFilterTextChange,
  isValidating,
  wrapToolbar,
}: Props<T>): JSX.Element {
  const { t } = useTranslation();
  const [filterText, setFilterText] = useState('');

  const [sortOptions, setSortOptions] = useState<SortingOptions>({
    sortBy: undefined,
    direction: undefined,
  });

  function toggleSort(c: Column<T>) {
    const nextDirection = toggleSortDirection(sortOptions.direction);
    const nextSortOptions: SortingOptions = {
      sortBy: nextDirection && c.id,
      direction: nextDirection,
    };
    setSortOptions(nextSortOptions);
  }

  const header = (
    <>
      {columns.map((c, i) => (
        <TableHeader
          key={i}
          align={c.align}
          onClick={c.isSortable ? () => toggleSort(c) : undefined}
        >
          <p className="inline-block whitespace-normal">{c.header}</p>
          {c.isSortable && (
            <SortIndicator
              sortDirection={
                c.id === sortOptions.sortBy ? sortOptions.direction : undefined
              }
            />
          )}
        </TableHeader>
      ))}
    </>
  );

  function updateFilterText(e: ChangeEvent<HTMLInputElement>) {
    const filter = e.target.value.toLowerCase();
    setFilterText(filter);
  }

  useEffect(() => {
    onSortOptionsChange?.(sortOptions);
  }, [sortOptions, onSortOptionsChange]);

  useEffect(() => {
    onFilterTextChange?.(filterText);
  }, [filterText, onFilterTextChange]);

  function getTableBody() {
    if (isValidating) return <LoadingIndicator />;
    if (rows?.length > 0) {
      return (
        <SimpleTable
          header={header}
          from={from}
          to={from + rows.length - 1}
          onPrev={onPrev}
          onNext={onNext}
          count={totalCount}
        >
          {rows.map((row, index) => (
            <tr
              key={row.id ?? index}
              onClick={() => onRowClick?.(row)}
              className={classNames(
                'hover:bg-accent-50 hover:rounded-lg',
                onRowClick && 'cursor-pointer',
              )}
            >
              {columns.map((c, i) => (
                <TableCell
                  key={i}
                  column={c}
                  row={row}
                  link={c.url?.(row) || rowLink?.(row)}
                />
              ))}
            </tr>
          ))}
        </SimpleTable>
      );
    }
    return (
      <p className="text-gray-400 font-normal mt-5">
        {altText ?? t('tables.altText')}
      </p>
    );
  }

  const additionalButtonsComponent = useMemo(() => {
    if (!additionalButtons) return null;
    const buttonsArray = Array.isArray(additionalButtons)
      ? additionalButtons
      : [additionalButtons];
    return buttonsArray.map((button, index) => {
      if (typeof button === 'function') {
        return button(filterText, sortOptions);
      }
      return { ...button, key: button.key ?? `button-${index}` };
    });
  }, [additionalButtons, filterText, sortOptions]);

  return (
    <>
      {(additionalButtonsComponent ||
        showSearch ||
        toolbar ||
        exportOptions?.exportable) && (
        <div
          className={classNames(
            wrapToolbar && 'flex-wrap',
            'inline-flex w-full gap-3 mb-3',
          )}
        >
          <div className="inline-flex items-center mb-4 sm:mb-0 sm:mt-0 flex-wrap gap-3 flex-grow">
            {additionalButtonsComponent}
            {exportOptions?.exportable && (
              <ExportSection
                exportData={exportOptions.exportData}
                exportFileName={exportOptions.exportFileName}
                exportTransform={exportOptions.exportTransform}
              />
            )}
            {showSearch && (
              <input
                type="text"
                id={styles.searchInput}
                defaultValue={filterText}
                onChange={debounce(updateFilterText, 300)}
                className="focus:ring-accent-600 font-medium focus:border-accent-600 flex-1 max-w-lg border-2 border-gray-300 text-sm rounded-lg bg-white placeholder-gray-400"
                placeholder={
                  searchPlaceholder ?? t('tables.searchPlaceholder') ?? ''
                }
              />
            )}
          </div>
          <div>{toolbar}</div>
        </div>
      )}
      {getTableBody()}
    </>
  );
}

function ExportSection<T extends { id?: string }>(
  exportOptions: FullExportOptions<T>,
): JSX.Element {
  const { t } = useTranslation('translation', { keyPrefix: 'tables' });
  const { setErrorMessage } = useErrorPopupContext();

  async function getData() {
    try {
      if (!exportOptions.exportData) return [];
      let rawData: T[] = [];
      if (Array.isArray(exportOptions.exportData)) {
        rawData = exportOptions.exportData;
      } else {
        rawData = await exportOptions.exportData();
      }
      return rawData.map((r) => exportOptions.exportTransform?.({ ...r }) ?? r);
    } catch (e) {
      setErrorMessage(t('couldNotExport'));
      captureException(e);
    }
  }

  return (
    <span className="align-top inline-flex">
      <DynamicAsyncExportButton
        getData={getData}
        exportFileName={exportOptions.exportFileName || ''}
      />
    </span>
  );
}

SortedFilteredTable.defaultProps = {
  showSearch: true,
  exportTransform: (i: unknown) => i,
  exportFileName: 'ExportData',
  filter: () => true,
};
