import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import {
  Column,
  ExportOptions,
  SortedFilteredTable,
  SortingOptions,
} from '../SortedFilteredTable/SortedFilteredTable';
import useWindowDimensions, {
  Breakpoints,
} from '../../../hooks/useWindowDimensions';
import { AdditionalButton } from '../PagedTable';
import authenticatedFetcher from 'data/authenticatedFetcher';

export type PagedFetchUrl = (
  skip: number | undefined,
  take: number | undefined,
  query?: string,
  sortOptions?: SortingOptions,
) => string;
export type CountFetchUrl = (query?: string) => string;

interface Props<T> {
  fetchUrl: PagedFetchUrl;
  countFetchUrl: CountFetchUrl;
  columns: Column<T>[];
  altText?: string;
  onRowClick?: (row: T) => void;
  rowLink?: (row: T) => string;
  searchPlaceholder?: string;
  showSearch?: boolean;
  limit?: number;
  onError?: (e: unknown) => void;
  exportOptions?: ExportOptions<T>;
  toolbar?: (rows: T[], isLoading: boolean, query: string) => JSX.Element;
  additionalButtons?: AdditionalButton | AdditionalButton[];
  wrapToolbar?: boolean;

  onRowsChange?: (rows: T[]) => void;
  onIsValidatingChange?: (value: boolean) => void;
}

export function getFallbackLimit(width: number): number {
  if (width > Breakpoints.MD) return 15;
  if (width > Breakpoints.SM) return 10;
  return 5;
}

/**
 * A wrapper for the tailwind simple table https://tailwindui.com/components/application-ui/lists/tables#component-3de290cc969415f170748791a9d263a6
 * Contains a footer for navigation if the parameters from, to and count and/or onPrev and onNext are provided
 * for an example, see StudentsOverview
 */
export function SSPagedTable<T extends { id?: string }>({
  fetchUrl,
  countFetchUrl,
  columns,
  altText,
  onRowClick,
  rowLink,
  onError,
  limit: limitProp,
  searchPlaceholder,
  showSearch,
  exportOptions,
  additionalButtons,
  toolbar,
  onRowsChange,
  onIsValidatingChange,
  wrapToolbar,
}: Props<T>): JSX.Element {
  const [filterText, setFilterText] = useState('');
  const [from, setFrom] = useState(1);

  const { data: count = 0 } = useSWR<number | null>(
    () => countFetchUrl(filterText),
    authenticatedFetcher,
    {
      onError: (err) => onError?.(err),
      fallbackData: 0,
    },
  );

  const [sortOptions, setSortOptions] = useState<SortingOptions>({
    sortBy: undefined,
    direction: undefined,
  });

  const { width } = useWindowDimensions();
  const limit = limitProp ?? getFallbackLimit(width);

  const { data: rows = [], isValidating } = useSWR<T[] | null>(
    () => fetchUrl(from - 1, limit, filterText, sortOptions),
    authenticatedFetcher,
    {
      onError: (err) => onError?.(err),
      revalidateOnFocus: false,
    },
  );

  // Prefetch previous and next page
  useSWR<T[] | null>(
    () =>
      from - limit - 1 >= 0 &&
      fetchUrl(from - limit - 1, limit, filterText, sortOptions),
    authenticatedFetcher,
    {
      revalidateOnFocus: false,
    },
  );
  useSWR<T[] | null>(
    () =>
      from + limit <= (count ?? 0) &&
      fetchUrl(from + limit - 1, limit, filterText, sortOptions),
    authenticatedFetcher,
    {
      revalidateOnFocus: false,
    },
  );

  function getExportData() {
    return authenticatedFetcher(
      fetchUrl(undefined, undefined, filterText, sortOptions),
    );
  }

  useEffect(() => {
    setFrom(1);
  }, [count, limit]);

  useEffect(() => {
    onIsValidatingChange?.(isValidating);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidating]);

  useEffect(() => {
    onRowsChange?.(rows ?? []);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  function onPrev() {
    if (from < limit) return;
    const newFrom = from - limit;
    setFrom(newFrom);
  }

  function onNext() {
    const newFrom = from + limit;
    if (newFrom > (count ?? 0)) return;
    setFrom(newFrom);
  }

  return (
    <SortedFilteredTable
      columns={columns}
      altText={altText}
      onRowClick={onRowClick}
      rowLink={rowLink}
      searchPlaceholder={searchPlaceholder}
      showSearch={showSearch}
      exportOptions={{ ...exportOptions, exportData: getExportData }}
      toolbar={!!rows ? toolbar?.(rows, isValidating, filterText) : undefined}
      additionalButtons={additionalButtons}
      rows={rows ?? []}
      from={from}
      totalCount={count ?? 0}
      onNext={onNext}
      onPrev={onPrev}
      isValidating={isValidating}
      onSortOptionsChange={setSortOptions}
      onFilterTextChange={setFilterText}
      wrapToolbar={wrapToolbar}
    />
  );
}

SSPagedTable.defaultProps = {
  showSearch: true,
  exportOptions: {
    exportTransform: (i: unknown) => i,
    exportFileName: 'ExportData',
  },
};
