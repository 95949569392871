import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { t } from 'i18next';
import React, {
  createRef,
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import useWindowDimensions, {
  Breakpoints,
} from '../../hooks/useWindowDimensions';
import { classNames } from 'utilities/classNames';

interface Props {
  headers?: string[];
  header?: ReactElement;
  from?: number;
  to?: number;
  count?: number;
  onPrev?: () => void;
  onNext?: () => void;
  className?: string;
  stickyHeader?: boolean;
}

/**
 * A wrapper for the tailwind simple table https://tailwindui.com/components/application-ui/lists/tables#component-3de290cc969415f170748791a9d263a6
 * Contains a footer for navigation if the parameters from, to and count and/or onPrev and onNext are provided
 * for an example, see StudentsOverview
 */
export const SimpleTable: FunctionComponent<Props> = ({
  children,
  headers,
  header,
  from,
  to,
  count,
  onPrev,
  onNext,
  className,
  stickyHeader,
}) => {
  const scrollTableWrapperRef = createRef<HTMLTableSectionElement>();
  const [scrollLeft, setScrollLeft] = useState(0);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const { width: screenWidth } = useWindowDimensions();

  useEffect(() => {
    if (scrollTableWrapperRef.current) {
      setShowRightArrow(
        scrollLeft <
          scrollTableWrapperRef.current.scrollWidth -
            scrollTableWrapperRef.current.offsetWidth,
      );
    }
  }, [scrollLeft, screenWidth, scrollTableWrapperRef]);

  return (
    <>
      {/* Horizontal scrolling css */}
      <div
        ref={scrollTableWrapperRef}
        className={classNames(
          'overflow-x-auto relative flex flex-row hide-scrollbar',
          className,
        )}
        onScroll={() => {
          scrollTableWrapperRef.current &&
            setScrollLeft(scrollTableWrapperRef.current.scrollLeft);
        }}
      >
        {scrollLeft > 10 && (
          <div className="sticky left-0 w-6 cursor-pointer flex -ml-6">
            <ChevronLeftIcon
              className="w-6 bg-gradient-to-r from-white hover:from-indigo-50 hover:to-indigo-50"
              onClick={() => {
                scrollTableWrapperRef.current &&
                  scrollTableWrapperRef.current.scrollBy({
                    behavior: 'smooth',
                    left: -130,
                  });
              }}
            />
          </div>
        )}
        <table className="w-full divide-y divide-gray-200">
          <thead className={classNames(stickyHeader && 'sticky top-0 z-50')}>
            <tr>
              {headers
                ? headers.map((header, i) => (
                    <TableHeader key={i} title={header} />
                  ))
                : header}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 font-light text-black">
            {children}
          </tbody>
        </table>
        {showRightArrow && (
          <div className="sticky right-0 w-6 cursor-pointer flex -ml-6">
            <ChevronRightIcon
              className="w-6 bg-gradient-to-l from-white hover:from-accent-50 hover:to-accent-50"
              onClick={() => {
                scrollTableWrapperRef.current &&
                  scrollTableWrapperRef.current.scrollBy({
                    behavior: 'smooth',
                    left: 130,
                  });
              }}
            />
          </div>
        )}
      </div>
      {!!((from && to && count) || (onPrev && onNext)) && (
        <nav
          className="bg-white px-4 py-3 border-t border-gray-200 flex items-center justify-between sticky left-0 sm:px-6 ipad-sticky space-x-2"
          aria-label="Pagination"
        >
          <div>
            {!!(from && to && count) && (
              <p className="text-sm text-gray-700 font-normal">
                {screenWidth > Breakpoints.SM
                  ? t('tables.paginationInfo', { from, to, count })
                  : t('tables.paginationInfoSmall', { from, to, count })}
              </p>
            )}
          </div>
          <div className="flex-1 flex justify-end">
            {!!onPrev && (
              <a
                onClick={onPrev}
                className="cursor-pointer border-transparent relative inline-flex items-center p-1 text-sm font-medium text-gray-700 border-2 bg-accent-50  hover:bg-accent-100 rounded-lg ease-in duration-100"
              >
                <ChevronLeftIcon className="text-accent-600 w-5 h-5" />
              </a>
            )}
            {!!onNext && (
              <a
                onClick={onNext}
                className="cursor-pointer ml-3 border-transparent relative inline-flex items-center p-1 text-sm font-medium text-gray-700 border-2 bg-accent-50  hover:bg-accent-100 rounded-lg ease-in duration-100"
              >
                <ChevronRightIcon className="text-accent-600 w-5 h-5" />
              </a>
            )}
          </div>
        </nav>
      )}
    </>
  );
};

SimpleTable.defaultProps = {
  from: undefined,
  to: undefined,
  count: undefined,
  onPrev: undefined,
  onNext: undefined,
};

interface HeaderProps {
  title?: string | React.ReactNode;
  align?: 'right' | 'left' | 'center';
  onClick?: () => void;
  wrap?: boolean;
}

export const TableHeader: FunctionComponent<HeaderProps> = ({
  title,
  align,
  onClick,
  children,
  wrap,
}) => {
  return (
    <th
      scope="col"
      className={classNames(
        'px-3 sm:px-4 py-5 text-left text-sm font-semibold text-accent-600 ',
        align && 'text-' + align,
        onClick && 'hover:cursor-pointer', // Indicates that a column is clickable (e.g. sortable)
        wrap ? 'whitespace-normal' : 'whitespace-nowrap',
      )}
      onClick={onClick}
    >
      {title ?? children}
    </th>
  );
};
