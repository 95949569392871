import React, { FunctionComponent } from 'react';
import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
} from '@heroicons/react/24/solid';
import { SortDirection } from '../../typings/backend-types';

export const SortIndicator: FunctionComponent<{
  sortDirection: SortDirection | undefined | null;
}> = ({ sortDirection }) => {
  return (
    <span className="inline-block align-middle">
      {sortDirection === SortDirection.ASC && (
        <ChevronDoubleUpIcon className="ml-1 h-4 w-5" />
      )}
      {sortDirection === SortDirection.DESC && (
        <ChevronDoubleDownIcon className="ml-1 h-4 w-5" />
      )}
      {!sortDirection && (
        <ChevronDoubleUpIcon className="ml-1 h-4 w-5 text-gray-300" />
      )}
    </span>
  );
};
