import Link from 'next/link';
import { classNames } from '../../../utilities/classNames';
import { Column } from './SortedFilteredTable';

interface Props<T> {
  column: Column<T>;
  row: T;
  link?: string;
}

export function TableCell<T>({ column, row, link }: Props<T>): JSX.Element {
  if (link) {
    return (
      <td
        className={classNames(
          'font-normal text-sm text-gray-500',
          column.align && 'text-' + column.align,
          'whitespace-pre-line',
        )}
      >
        <Link href={link} passHref>
          {/* include the padding in the a tag to make the link area as big as possible */}
          <a className="block px-3 sm:px-4 py-4 h-full cursor-pointer hover:text-gray-700">
            {column.prop(row)}
          </a>
        </Link>
      </td>
    );
  }

  return (
    <td
      className={classNames(
        'px-3 sm:px-4 py-4 text-sm text-gray-500',
        column.align && 'text-' + column.align,
        'whitespace-pre-line',
      )}
    >
      {column.prop(row)}
    </td>
  );
}
